<template>
  <div>
    <div class="gh-xs gv-xs mb-n7 row">
      <div class="col-auto col" v-for="(item, i) in options" :key="i">
        <input
          :id="getIdByLabel(item)"
          type="checkbox"
          class="checkbox-btn form-check-input"
          :checked="isSelected(item)"
          @change="handleCheckboxChange(item)"
        />
        <label :for="getIdByLabel(item)">
          <span>{{ item }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChooseTypeProject",
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOptions: [],
    };
  },
  watch: {
    selectedOptions(newOptions) {
      this.$emit("getCurrentOptions", [...newOptions]);
    },
  },
  methods: {
    getIdByLabel(label) {
      const formattedLabel = label.toLowerCase().replace(/\s/g, "");
      return `idFor${formattedLabel
        .charAt(0)
        .toUpperCase()}${formattedLabel.slice(1)}`;
    },
    isSelected(item) {
      return this.selectedOptions.includes(item);
    },
    handleCheckboxChange(item) {
      if (this.isSelected(item)) {
        this.selectedOptions = this.selectedOptions.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        this.selectedOptions.push(item);
      }
    },
  },
};
</script>
