<template>
  <Layout>
    <div class="contact-section pt-5">
      <div class="container">
        <div class="row">
          <h1 class="display-4 text-white mt-5 mb-5">
            Conversemos
          </h1>
        </div>
        <div class="row">
          <p class="lead fw-medium mt-5 mb-5">
            Cuéntanos sobre tu proyecto
          </p>
          <choose-type-project
            :options="projectOptions"
            @getCurrentOptions="handleSelectedOptions($event)"
          ></choose-type-project>
        </div>

        <div class="row pt-5 mt-3">
          <form ref="myForm" @submit.prevent="submitForm">
            <div class="gh-1 gv-2 row">
              <div class="col-12 col-md-6">
                <input
                  placeholder="¿Cuál es tu nombre? *"
                  v-model="formData.name"
                  name="name"
                  type="name"
                  class="form-control form-control"
                  required
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  placeholder="¿A qué correo nos comunicamos? *"
                  v-model="formData.email"
                  name="email"
                  type="email"
                  class="form-control form-control"
                  required
                />
              </div>
              <div class="col-12 col-md-6">
                <input
                  placeholder="¿A qué número de whatsapp nos podemos comunicar?"
                  v-model="formData.whatsapp"
                  type="phone"
                  name="whatsapp"
                  class="form-control form-control"
                />
              </div>
              <div class="col">
                <select
                  class="form-control"
                  name="budget"
                  v-model="formData.budget"
                  required
                >
                  <option value="" selected>¿Cuál es tu presupuesto?</option>
                  <option value="< 10 000">&lt; 10 000</option>
                  <option value="10-20k">10-20k</option>
                  <option value="20-30k">20-30k</option>
                  <option value="> 30 000">&gt; 30 000</option>
                </select>
              </div>
              <div class="col-12 col">
                <textarea
                  class="form-control"
                  rows="1"
                  placeholder="Describé tu producto *"
                  v-model="formData.message"
                  name="message"
                  required
                ></textarea>
              </div>
              <div class="col-12 col aos-init">
                <button class="btn button--kiiner" type="submit" name="button">
                  Enviar
                </button>
              </div>
            </div>
          </form>
          <!-- <div
            v-if="successMessage"
            class="alert alert-success mt-3"
            role="alert"
          >
            <h4 class="alert-heading">¡Bien hecho!</h4>
            <p>{{ successMessage }}</p>
            <p class="mb-0">¡Gracias por ponerte en contacto con nosotros!</p>
          </div> -->
        </div>
      </div>
    </div>
    <sedes></sedes>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";
import ChooseTypeProject from "../components/ChooseTypeProject.vue";
import Sedes from "../components/Sedes.vue";

export default {
  name: "Contact",
  components: {
    Layout,
    ChooseTypeProject,
    Sedes,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        whatsapp: "",
        type: "",
        budget: "",
        message: "",
      },
      projectOptions: [
        "Web Design",
        "App Design",
        "Logo Design",
        "Illustration",
        "Photography",
        "Web Development",
        "App Development",
        "Branding",
      ],
      selectedOptions: [],
      successMessage: "",
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  },
  methods: {
    async submitForm() {
      if (
        !this.formData.name ||
        !this.formData.email ||
        !this.formData.budget ||
        !this.formData.message ||
        !this.formData.type
      ) {
        alert("Please fill in all mandatory fields");
        return;
      }

      try {
        const recaptchaToken = await this.executeRecaptcha();
        const result = await fetch("/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...this.formData,
            token: recaptchaToken,
          }),
        });

        if (result.ok) {
          this.successMessage = "¡Tu correo se ha enviado correctamente!";
          this.resetForm();
        } else {
          console.error("Error sending email");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async executeRecaptcha() {
      const KEY = "6LcsVlQpAAAAAC7BK_ArzhkfXUTbVw07MrP2euVZ";
      return new Promise((resolve, reject) => {
        grecaptcha.enterprise.ready(async () => {
          try {
            const token = await grecaptcha.enterprise.execute(KEY, {
              action: "LOGIN",
            });
            resolve(token);
          } catch (error) {
            reject(error);
          }
        });
      });
    },
    handleSelectedOptions($event) {
      this.formData.type = $event.join(", ");
    },
    resetForm() {
      this.formData = {
        name: "",
        email: "",
        whatsapp: "",
        type: "",
        budget: "",
        message: "",
      };
    },
  },
};
</script>
